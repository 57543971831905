import * as React from 'react';
import ReactDOM from 'react-dom';
import Markdown from 'common/components/Markdown';
import { WidgetCommonProps } from './interfaces/interfaces';
import styled from 'styled-components';
import Button from 'styleguide/components/Buttons/Button/Button';
import { mediaBreakpoints, media } from 'styleguide/helpers/media';
import { PopupConfiguration } from './interfaces/Popup';
import { TextStyles } from './Text';
import { ContentTheme, ContentThemeContext } from 'common/contexts/ContentThemeContext';

const PopupWrapper = styled.div<ContentTheme>`
	position: fixed;
	top: 0;
	left: 0;
	background-color: #fff;
	width: 100%;
	height: 100%;
	max-width: ${mediaBreakpoints.tablet}px;
	z-index: ${({ theme }) => theme.zIndices.headerWithSearchBox};
	border-radius: 3px;
	overflow: hidden;

	${media.tablet<ContentTheme>`
    top: 50%;
    left: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    border: 2px solid ${({ color }) => color};
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
  `}
`;

const PopupInner = styled.div<ContentTheme>`
	${TextStyles};
	padding: 70px 30px 30px;
	margin: 0 auto 80px;
	overflow-y: auto;
	height: calc(100% - 80px);

	${media.tablet`
    padding: 30px;
  `}
`;

const ButtonWrapper = styled.div<ContentTheme>`
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 15px;
	display: flex;
	justify-content: center;
	border-top: 1px solid ${({ color }) => color};
`;

interface PopupContentProps extends PopupConfiguration {
	onClose: () => void;
	color: string;
}

const PopupContents: React.FC<PopupContentProps> = ({ content, onClose, color }) => (
	<PopupWrapper color={color}>
		<PopupInner color={color}>
			<Markdown source={content} escapeHtml={true} />
		</PopupInner>
		<ButtonWrapper color={color}>
			<Button onClick={onClose}>Sulje</Button>
		</ButtonWrapper>
	</PopupWrapper>
);

type Props = WidgetCommonProps & PopupConfiguration;

class Popup extends React.Component<Props> {
	private portalElement: HTMLDivElement;
	private elementCreated: boolean = false;

	constructor(props: Props) {
		super(props);

		const { widgetId } = this.props;
		const elementId = `${widgetId}-popupwidget`;
		let element = document.getElementById(elementId);

		if (!element) {
			this.elementCreated = true;
			element = document.createElement('div');
			element.id = elementId;
			document.body.appendChild(element);
		}

		this.portalElement = element as HTMLDivElement;
	}

	public componentWillUnmount() {
		if (this.elementCreated) {
			document.body.removeChild(this.portalElement);
		}
	}

	public render() {
		const { content } = this.props;

		return ReactDOM.createPortal(
			<ContentThemeContext.Consumer>
				{contentTheme => <PopupContents content={content} onClose={this.onPopupClose} color={contentTheme.color} />}
			</ContentThemeContext.Consumer>,
			this.portalElement
		);
	}

	private onPopupClose = () => {
		document.body.removeChild(this.portalElement);
	};
}

export default Popup;
