import * as React from 'react';
import styled, { css, DefaultTheme, useTheme } from 'styled-components';
import { ArrowRightIcon } from 'styleguide/components/Icons';
import { media, mediaBreakpoints } from 'styleguide/helpers';
import { Icon } from 'utils/import';
import { InfoboxColor, InfoboxConfiguration } from './interfaces/Infobox';
import { WidgetCommonProps } from './interfaces/interfaces';

interface InfoboxColorConfigs {
	bgColor: string;
	titleColor: string;
	textColor: string;
}

const wrapperStyles = css<{ color: string }>`
	background-color: ${({ color }) => color};
	padding: ${({ theme }) => theme.grid.gutterInPx(2)};
	margin: ${({ theme }) => theme.grid.gutterInPx(2)} auto;
	max-width: ${mediaBreakpoints.tablet}px;
	display: block;
	position: relative;
`;

const InfoboxWrapper = styled.div`
	${wrapperStyles};
`;

const InfoboxLinkWrapper = styled.a<{ color: string }>`
	${wrapperStyles};
	text-decoration: none;

	&:visited {
	}
	&:hover {
	}
	&:focus {
	}
`;

const InfoboxInner = styled.div<{ borderColor: string }>`
	border: 1px solid ${({ borderColor }) => borderColor};
	padding: ${({ theme }) => theme.grid.gutterInPx(6)} ${({ theme }) => theme.grid.gutterInPx(4)};
`;

const InfoboxGrid = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-flow: column;
	align-content: flex-start;

	${media.tablet`
		flex-flow: row nowrap;
		align-content: center;
	`}
`;

const IconWrapper = styled.div<{ isStrongBg: boolean }>`
	flex: 0 0 80px;
	align-self: center;
	text-align: center;

	> * {
		width: 44px;
		height: 44px;
		vertical-align: middle;
		filter: ${({ isStrongBg }) =>
			isStrongBg
				? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(108deg) brightness(104%) contrast(104%)'
				: 'brightness(0) saturate(100%) invert(9%) sepia(79%) saturate(3742%) hue-rotate(206deg) brightness(97%) contrast(102%)'};
	}
`;

const TitleWrapper = styled.div<{ titleColor: string }>`
	${({ theme }) => theme.typography.heading};
	color: ${({ titleColor }) => titleColor};
	font-size: 16px;
	flex: 1 1 50%;
	align-self: center;

	${media.tablet`
		margin-right: ${({ theme }) => theme.grid.gutterInPx(3)};
	`}
`;

const DescriptionWrapper = styled.div<{ textColor: string }>`
	flex: 1 1 50%;
	align-self: center;
	font-size: 14px;
	font-weight: 500;
	color: ${({ textColor }) => textColor};
	margin-top: ${({ theme }) => theme.grid.gutterInPx(3)};
	text-align: center;

	${media.tablet`
		margin-top: 0;
		margin-left: ${({ theme }) => theme.grid.gutterInPx(3)}; 
		text-align: left;
	`}
`;

const ArrowIconWrapper = styled.div`
	position: absolute;
	bottom: ${({ theme }) => theme.grid.gutterInPx(3)};
	right: ${({ theme }) => theme.grid.gutterInPx(3)};

	> * {
		width: 22px;
		height: 22px;
	}
`;

const getColorConfig = (color: InfoboxColor, theme: DefaultTheme): InfoboxColorConfigs => {
	switch (color) {
		case 'strong':
			return {
				bgColor: theme.colors.infoboxStrong,
				titleColor: theme.colors.white,
				textColor: theme.colors.white,
			};
		case 'medium':
			return {
				bgColor: theme.colors.infoboxMedium,
				titleColor: theme.colors.brandPrimary,
				textColor: theme.colors.bodyText,
			};
		case 'light':
			return {
				bgColor: theme.colors.infoboxLight,
				titleColor: theme.colors.brandPrimary,
				textColor: theme.colors.bodyText,
			};
		default:
			return {
				bgColor: theme.colors.infoboxStrong,
				titleColor: theme.colors.white,
				textColor: theme.colors.white,
			};
	}
};

const Infobox: React.FC<WidgetCommonProps & InfoboxConfiguration> = ({
	title,
	description,
	icon,
	color,
	url,
	widgetId,
}) => {
	const theme = useTheme();
	const { bgColor, textColor, titleColor } = getColorConfig(color, theme);

	const iconComponent = <Icon iconName={icon} color={titleColor} />;

	const renderInfoboxContents = () => (
		<>
			<InfoboxInner borderColor={titleColor}>
				<InfoboxGrid>
					{iconComponent && <IconWrapper isStrongBg={color === 'strong'}>{iconComponent}</IconWrapper>}
					<TitleWrapper titleColor={titleColor}>{title}</TitleWrapper>
					<DescriptionWrapper textColor={textColor}>{description}</DescriptionWrapper>
				</InfoboxGrid>
			</InfoboxInner>
			{url && (
				<ArrowIconWrapper>
					<ArrowRightIcon color={titleColor} />
				</ArrowIconWrapper>
			)}
		</>
	);

	return url ? (
		<InfoboxLinkWrapper href={url} color={bgColor} id={widgetId}>
			{renderInfoboxContents()}
		</InfoboxLinkWrapper>
	) : (
		<InfoboxWrapper color={bgColor} id={widgetId}>
			{renderInfoboxContents()}
		</InfoboxWrapper>
	);
};

export default Infobox;
