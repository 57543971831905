import * as React from 'react';
import Modal from 'styleguide/components/Modals/Modal';
import { ModalStates, SupportedNewsletters } from '../interfaces';
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11';
import Input from 'styleguide/components/Inputs/Input';
import { ValidationPatterns } from 'common/components/Forms/helpers';
import config from '../config';
import { Button } from 'styleguide/components/Buttons/Button';
import Alert, { AlertType } from 'styleguide/components/Typography/Alert';
import { useIntl, FormattedMessage } from 'react-intl';
import LoadingSpinner from 'common/components/Loading/LoadingSpinner';
import MediumHeader from 'styleguide/components/Typography/MediumHeader';
import styled from 'styled-components';
import { media } from 'styleguide/helpers';

const StyledModal = styled(Modal)`
	.medium-header {
		margin: 0 0 ${({ theme }) => theme.grid.gutterInPx(4)};
	}

	${media.tablet`
		height: auto;
	`};
`;

const Form = styled.form`
	h4 {
		font-weight: 600;
		margin: ${({ theme }) => theme.grid.gutterInPx(4)} 0 ${({ theme }) => theme.grid.gutterInPx()};
	}

	.newsletter-error {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(4)};
	}

	.loading-spinner {
		margin: ${({ theme }) => theme.grid.gutterInPx(4)} auto;
	}
`;

const FormActions = styled.div`
	margin-top: ${({ theme }) => theme.grid.gutterInPx(4)};
	text-align: center;

	${media.tablet`
		display: flex;
		justify-content: space-between;
		align-items: center;
	`};

	.newsletter-submit {
		min-width: 125px;
		margin: 0 auto;

		${media.tablet`
			margin: 0;
			min-width: 240px;
		`};
	}
`;

const CancelButton = styled.button`
	font-family: ${({ theme }) => theme.fonts.secondary};
	font-size: 14px;
	line-height: 20px;
	color: ${({ theme }) => theme.colors.buttonPrimary};
	background: none;
	border: none;
	padding: 0;
	margin: ${({ theme }) => theme.grid.gutterInPx(2)} 0 0;
	cursor: pointer;

	${media.tablet`
		margin: 0;
	`};
`;

const NewsletterItems = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

const NewsletterItem = styled.li`
	position: relative;
	text-align: left;
	padding-left: 21px;
	font-size: 14px;
	line-height: 20px;

	&:before {
		content: '';
		display: inline-block;
		height: 4px;
		width: 12px;
		border-left: 2px solid ${({ theme }) => theme.colors.shoppingListGreen};
		border-bottom: 2px solid ${({ theme }) => theme.colors.shoppingListGreen};
		transform: rotate(-45deg);
		position: absolute;
		left: 0;
		top: 7px;
	}
`;

interface NewsletterModalProps {
	chosenNewsletters: SupportedNewsletters[];
	modalState: ModalStates;
	onClose: () => void;
	onSubscribe: (email: string) => void;
}

interface NewsletterFormData {
	email: string;
}

const NewsletterModal: React.FC<NewsletterModalProps> = ({ chosenNewsletters, onClose, onSubscribe, modalState }) => {
	const intl = useIntl();

	const { handleSubmit, errors, register } = useForm<NewsletterFormData>();

	const onSubmit = (fieldValues: NewsletterFormData) => {
		onSubscribe(fieldValues.email);
	};

	return (
		<StyledModal onClose={onClose}>
			{modalState !== 'MODAL_STATE_CONFIRMATION' && (
				<>
					<MediumHeader className="medium-header">
						<FormattedMessage id="widget_newsletter_fill_form_to_order" />
					</MediumHeader>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Input
							type="email"
							name="email"
							placeholder={intl.formatMessage({ id: 'forms_write_your_email_address' })}
							label={intl.formatMessage({ id: 'forms_email_address' })}
							validationError={errors.email?.message}
							ref={register({
								required: { value: true, message: intl.formatMessage({ id: 'forms_validation_email_empty' }) },
								pattern: {
									value: new RegExp(ValidationPatterns.EMAIL),
									message: intl.formatMessage({ id: 'forms_validation_email_check' }),
								},
							})}
						/>
						<h4>
							<FormattedMessage id="widget_newsletter_you_are_ordering" />:
						</h4>
						<NewsletterItems>
							{chosenNewsletters.map(type => {
								const { title } = config[type];
								return (
									<NewsletterItem key={type}>
										<FormattedMessage id={title} />
									</NewsletterItem>
								);
							})}
						</NewsletterItems>
						{modalState === 'MODAL_STATE_ERROR' && (
							<Alert
								type={AlertType.Error}
								className="newsletter-error"
								highlightedText={`${intl.formatMessage({ id: 'global_error' })}:`}>
								{intl.formatMessage({ id: 'widget_newsletter_order_error' })}
							</Alert>
						)}
						{modalState === 'MODAL_STATE_REQUEST' && <LoadingSpinner className="loading-spinner" />}
						<FormActions>
							<Button
								className="newsletter-submit"
								type="submit"
								size="medium"
								disabled={modalState === 'MODAL_STATE_REQUEST'}>
								<FormattedMessage id="global_order" />
							</Button>
							<CancelButton type="button" onClick={onClose}>
								<FormattedMessage id="global_cancel_choice" />
							</CancelButton>
						</FormActions>
					</Form>
				</>
			)}
			{modalState === 'MODAL_STATE_CONFIRMATION' && (
				<>
					<MediumHeader>
						<FormattedMessage id="widget_newsletter_thank_you" />
					</MediumHeader>
					<p>
						<FormattedMessage id="widget_newsletter_order_succeeded" />
					</p>
					<Button size="medium" onClick={onClose}>
						<FormattedMessage id="global_close" />
					</Button>
				</>
			)}
		</StyledModal>
	);
};

export default NewsletterModal;
