import * as React from 'react';
import { FlocklerConfiguration } from './interfaces';
import styled from 'styled-components';
import { WIDGET_SPACING_LARGE } from '../constants';
import { WidgetCommonProps } from '../interfaces/interfaces';
import { useScript } from 'common/hooks/useScript';
import MediumHeader from 'styleguide/components/Typography/MediumHeader';

const FlocklerWrapper = styled.article`
	margin: ${WIDGET_SPACING_LARGE}px auto;
	text-align: center;
`;

const FlocklerContentWrapper = styled.div``;

type Props = WidgetCommonProps & FlocklerConfiguration;

const FlocklerFeed: React.FC<Props> = ({ feedAreaPlaceHolder, feedScriptSource, title }) => {
	if (feedScriptSource) {
		useScript({ src: feedScriptSource, async: true });
	}

	return (
		<FlocklerWrapper>
			{title && <MediumHeader tagName="h2">{title}</MediumHeader>}
			<FlocklerContentWrapper dangerouslySetInnerHTML={createHtmlMarkup(feedAreaPlaceHolder)} />
		</FlocklerWrapper>
	);
};

function createHtmlMarkup(feedSource: string) {
	return { __html: feedSource };
}

export default FlocklerFeed;
