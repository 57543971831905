import * as React from 'react';
import { WidgetCommonProps } from '../interfaces/interfaces';
import { ContactPersonsConfiguration } from './interfaces';
import styled, { DefaultTheme, ThemeProps } from 'styled-components';
import { WIDGET_SPACING_LARGE } from '../constants';
import { media } from 'styleguide/helpers';
import { ContentThemeContext, ContentTheme } from 'common/contexts/ContentThemeContext';
import ContactPerson from './ContactPerson';
import { generate } from 'shortid';

const ContactPersonsWrapper = styled.article`
	font-family: ${({ theme }) => theme.fonts.secondary};
	margin: ${WIDGET_SPACING_LARGE}px auto;
`;

const Header = styled.header`
	max-width: 720px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: ${({ theme }) => theme.grid.gutterInPx(6)};

	${media.desktop`
    margin-bottom: ${({ theme }) => theme.grid.gutterInPx(8)};
  `};
`;

const Title = styled.h3<ContentTheme>`
	${({ theme }) => theme.typography.heading};
	font-size: 24px;
	line-height: 32px;
	color: ${props => props.color};
	margin: 0 0 ${({ theme }) => theme.grid.gutterInPx(2)};
`;

const Description = styled.p`
	${media.desktop`
    font-size: 18px;
    line-height: 24px;
  `};
`;

interface ContactPersonsListProps {
	hasPictures?: boolean;
}

const ContactPersonsList = styled.ul<ContactPersonsListProps>`
	list-style: none;
	margin: 0;
	padding: 0;
	overflow: hidden;

	${media.phone660`
    display: flex;
    flex-wrap: wrap;
    margin: ${({ theme }) => theme.grid.gutterInPx(-1.5)};

    @supports (display: grid) {
      display: grid;
      grid-gap: ${({ theme }) => theme.grid.gutterInPx(3)};
      grid-template-columns: repeat(2, 1fr);
      margin: 0;
    }
  `};

	${media.desktop`
    margin: ${({ theme }) => theme.grid.gutterInPx(-2.5)};

    @supports (display: grid) {
      grid-gap: ${({ theme }) => theme.grid.gutterInPx(5)};
      grid-template-columns: repeat(${(props: ThemeProps<DefaultTheme>) =>
				((props as unknown) as ContactPersonsListProps).hasPictures ? 3 : 4}, 1fr);
      margin: 0;
    }
  `};

	li {
		${media.phone660`
      flex: 0 1 auto;
      width: calc(100% / 2 - ${({ theme }) => theme.grid.gutterInPx(3)});
      margin: ${({ theme }) => theme.grid.gutterInPx(1.5)};

      @supports (display: grid) {
        width: auto;
        margin: 0;
      }
    `};

		${media.desktop`
      width: calc(100% / ${(props: ThemeProps<DefaultTheme>) =>
				((props as unknown) as ContactPersonsListProps).hasPictures ? 3 : 4} - ${({ theme }) =>
			theme.grid.gutterInPx(5)});
      margin: ${({ theme }) => theme.grid.gutterInPx(2.5)};

      @supports (display: grid) {
        width: auto;
        margin: 0;
      }
    `};
	}

	li + li {
		@supports (display: grid) {
			margin-top: ${({ theme }) => theme.grid.gutterInPx(3)};
		}

		${media.phone660`
      @supports (display: grid) {
        margin-top: 0;
      }
    `};
	}
`;

const ContactPersons: React.FC<WidgetCommonProps & ContactPersonsConfiguration> = ({
	title,
	description,
	contacts,
	widgetId,
	layoutName,
}) => {
	const showPictures = layoutName !== 'NoPictures';

	return (
		<ContentThemeContext.Consumer>
			{contentTheme => (
				<ContactPersonsWrapper id={widgetId}>
					{(title || description) && (
						<Header>
							{title && <Title color={contentTheme.color}>{title}</Title>}
							{description && <Description>{description}</Description>}
						</Header>
					)}
					<ContactPersonsList hasPictures={showPictures}>
						{contacts.map(contact => (
							<ContactPerson key={generate()} layoutName={layoutName} {...contact} />
						))}
					</ContactPersonsList>
				</ContactPersonsWrapper>
			)}
		</ContentThemeContext.Consumer>
	);
};

export default ContactPersons;
