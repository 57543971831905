import * as React from 'react';
import { WidgetCommonProps } from '../interfaces/interfaces';
import { CountrySelectorConfiguration } from './interfaces';
import { generate } from 'shortid';
import Country from './Country';
import { WIDGET_SPACING_LARGE } from '../constants';
import { media } from 'styleguide/helpers';
import WidgetText from '../components/WidgetText';
import MediumHeader from 'styleguide/components/Typography/MediumHeader';
import styled from 'styled-components';

const CountrySelectorWrapper = styled.article`
	font-family: ${({ theme }) => theme.fonts.secondary};
	margin: ${WIDGET_SPACING_LARGE}px auto;
`;

const Header = styled.header`
	text-align: center;
	margin-bottom: ${({ theme }) => theme.grid.gutterInPx(6)};

	${media.desktop`
    margin-bottom: ${({ theme }) => theme.grid.gutterInPx(8)};
  `};
`;

const CountryList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	${media.phone660`
		flex-direction: row;
	`};
`;

const CountrySelector: React.FC<WidgetCommonProps & CountrySelectorConfiguration> = ({
	title,
	description,
	countries,
}) => {
	return (
		<CountrySelectorWrapper>
			{(title || description) && (
				<Header>
					{title && <MediumHeader>{title}</MediumHeader>}
					{description && <WidgetText>{description}</WidgetText>}
				</Header>
			)}
			<CountryList>
				{countries.map(country => (
					<Country key={generate()} {...country} />
				))}
			</CountryList>
		</CountrySelectorWrapper>
	);
};

export default CountrySelector;
