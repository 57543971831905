import * as React from 'react';
import { ContactPersonInterface } from './interfaces';
import styled from 'styled-components';
import { ContentThemeContext, ContentTheme } from 'common/contexts/ContentThemeContext';
import Image from 'styleguide/components/PicturesAndVideos/Image';
import { media } from 'styleguide/helpers';

const ContactPersonWrapper = styled.li`
	display: flex;
`;

const Title = styled.h4<ContentTheme>`
	${({ theme }) => theme.typography.heading};
	${({ theme }) => theme.typography.contactPersonName};
	color: ${props => props.color};
	margin: 0 0 ${({ theme }) => theme.grid.gutterInPx(0.5)};
`;

const Position = styled.p`
	font-size: 14px;
	line-height: 20px;
	margin: 0 0 ${({ theme }) => theme.grid.gutterInPx(2)};
`;

const ContactInfo = styled.p`
	font-size: 14px;
	line-height: 20px;
	margin: 0 0 ${({ theme }) => theme.grid.gutterInPx(2)};
`;

const ImageWrapper = styled.div`
	width: 100px;
	height: 100px;
	flex: 0 0 100px;
	margin-right: ${({ theme }) => theme.grid.gutterInPx(3)};

	${media.tablet`
    width: 117px;
    height: 148px;
    flex: 0 0 117px;
  `};

	img {
		max-width: 100%;
		max-height: 100%;
	}
`;

const DetailsWrapper = styled.div`
	flex: 1 1 auto;
`;

const ContactPerson: React.FC<ContactPersonInterface> = ({
	name,
	position,
	area,
	phone,
	email,
	imageUrl,
	layoutName,
}) => {
	const showPictures = layoutName !== 'NoPictures';

	return (
		<ContentThemeContext.Consumer>
			{contentTheme => (
				<ContactPersonWrapper>
					{showPictures && (
						<ImageWrapper>
							<Image src={`${imageUrl}/117x148`} alt={`${name} - ${position}${area ? `, ${area}` : ''}`} />
						</ImageWrapper>
					)}
					<DetailsWrapper>
						<Title color={contentTheme.color}>{name}</Title>
						<Position>
							<strong>{position}</strong>
							{area && (
								<>
									<br /> {area}
								</>
							)}
						</Position>
						<ContactInfo>
							{phone}
							<br />
							{email}
						</ContactInfo>
					</DetailsWrapper>
				</ContactPersonWrapper>
			)}
		</ContentThemeContext.Consumer>
	);
};

export default ContactPerson;
