import * as React from 'react';
import { WidgetCommonProps } from '../interfaces/interfaces';
import { NewsletterWidgetConfiguration, SupportedNewsletters, ModalStates } from './interfaces';
import styled, { css } from 'styled-components';
import { media } from 'styleguide/helpers';
import { Icon } from 'utils/import';
import { ContentWrapper } from 'common/components/General';
import { TEXT_WIDTH_NARROW, TEXT_WIDTH, WIDGET_SPACING_MEDIUM } from '../constants';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';
import NewsletterCard from './components/NewsletterCard';
import NewsletterModal from './components/NewsletterModal';
import { newsletterApi } from './services';

interface NewsletterWrapperProps {
	isFullWidth: boolean;
}

const NewsletterWrapper = styled.article<NewsletterWrapperProps>`
	background: ${({ theme }) => theme.colors.contentSectionHiglighted};
	text-align: center;

	${props => {
		if (props.isFullWidth) {
			return css`
				padding: ${WIDGET_SPACING_MEDIUM}px 0;
			`;
		}
		return css`
			padding: ${({ theme }) => theme.grid.gutterInPx(2)};
			border-radius: 10px;
			margin: ${WIDGET_SPACING_MEDIUM}px auto;

			${media.tablet`
				padding: ${({ theme }) => theme.grid.gutterInPx(4)};
			`};

			${media.desktop1200`
				padding: ${({ theme }) => theme.grid.gutterInPx(8)};
			`};
		`;
	}}
`;

const Header = styled.header`
	max-width: ${TEXT_WIDTH_NARROW}px;
	padding: 0 ${({ theme }) => theme.grid.gutterInPx(2)};

	${media.desktop1440`
    max-width: ${TEXT_WIDTH}px;
		padding: 0;
  `};

	& > img {
		display: block;
		background: ${({ theme }) => theme.colors.white};
		border-radius: 50%;
		width: 100px;
		height: 100px;
		padding: ${({ theme }) => theme.grid.gutterInPx()};
		margin: 0 auto ${({ theme }) => theme.grid.gutterInPx()};

		${media.tablet`
			margin: 0 auto ${({ theme }) => theme.grid.gutterInPx(2)};
		`};

		${media.desktop`
			margin: 0 auto;
		`};
	}
`;

const TitleWrapper = styled.div`
	text-align: center;
`;

const Title = styled.h2`
	${({ theme }) => theme.typography.heading};
	text-align: center;
	font-size: 36px;
	line-height: 1;
	color: ${({ theme }) => theme.colors.brandPrimary};
	margin: 0 auto ${({ theme }) => theme.grid.gutterInPx(2)};

	${media.tablet`
		margin: ${({ theme }) => theme.grid.gutterInPx(2)} 0 ${({ theme }) => theme.grid.gutterInPx(3)};
	`};

	${media.desktop`
		font-size: 48px;
		margin: ${({ theme }) => theme.grid.gutterInPx(3)} 0 ${({ theme }) => theme.grid.gutterInPx(4)};
	`};
`;

const LeadText = styled.p`
	font-size: 16px;
	line-height: 22px;
	margin: 0;

	${media.tablet`
		font-size: 18px;
		line-height: 24px;
	`};

	${media.desktop`
		font-size: 22px;
		line-height: 28px;
	`};
`;

const CardContainer = styled.ul`
	padding: 0;
	list-style: none;
	margin: 0 ${({ theme }) => theme.grid.gutterInPx(2)} ${({ theme }) => theme.grid.gutterInPx(2)};

	${media.tablet`
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: ${({ theme }) => theme.grid.gutterInPx(-2)};
	`};

	${media.desktop1200`
		margin: ${({ theme }) => theme.grid.gutterInPx(-4)};
	`};
`;

interface OrderContainerProps {
	visible: boolean;
}

const OrderContainer = styled.div<OrderContainerProps>`
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	background: ${({ theme }) => theme.colors.brandPrimary};
	padding: ${({ theme }) => theme.grid.gutterInPx(2)};
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: transform 200ms ease-out;
	transform: translateY(${props => (props.visible ? '0' : '100%')});
	z-index: ${({ theme }) => theme.zIndices.flyingThings};

	${media.tablet`
		display: block;
		position: static;
		z-index: 1;
		transform: translateY(0);
		padding: 0;
		background: none;
		margin-top: ${({ theme }) => theme.grid.gutterInPx(8)};
	`};
`;

// const OrderButton = styled(Button)`
// 	${media.tablet`
// 		margin: 0 auto;
// 		min-height: ${getButtonHeight('large')};
// 		padding: ${props => getButtonVerticalPadding('large')} ${props => getButtonPadding('large')};
// 		min-width: 240px;
// 	`};
// `;

const ChosenIndicator = styled.span`
	${({ theme }) => theme.typography.heading};
	font-size: 13px;
	color: ${({ theme }) => theme.colors.white};

	${media.tablet`
		display: none;
	`};
`;

const DisclaimerContainer = styled.div`
	margin: ${({ theme }) => theme.grid.gutterInPx(4)} auto 0;
	font-size: 14px;

	p {
		margin: 0;

		& + & {
			margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};
		}
	}
`;

function getSubscriptionLink(newsletterType: SupportedNewsletters): string {
	switch (newsletterType) {
		default:
		case 'ValioNewsletter':
		case 'Baking':
		case 'FamilyNewsletter':
			return 'https://uutiskirje.valio.fi';
		case 'NutritionAndHealth':
		case 'ChildrenNutrition':
			return 'https://valio-ravitsemusammattilaiset-uutiskirje.valio.fi';
	}
}

const NewsletterOrder: React.FC<WidgetCommonProps & NewsletterWidgetConfiguration> = ({
	widgetId,
	title: widgetTitle,
	description: widgetDescription,
	icon,
	newsletters,
	zone,
}) => {
	const intl = useIntl();

	const [chosenNewsletters, setChosenNewsletters] = React.useState<SupportedNewsletters[]>([]);
	const [modalState, setModalState] = React.useState<ModalStates>('MODAL_STATE_CLOSED');

	const onSubscribe = async (email: string) => {
		try {
			setModalState('MODAL_STATE_REQUEST');
			await newsletterApi.orderNewsletters({ email, newsletters: chosenNewsletters });
			setModalState('MODAL_STATE_CONFIRMATION');
			setChosenNewsletters([]);
		} catch (error) {
			setModalState('MODAL_STATE_ERROR');
		}
	};

	const onClose = () => {
		setModalState('MODAL_STATE_CLOSED');
	};

	const onSelect = (type: SupportedNewsletters, checked: boolean) => {
		if (checked) {
			const subscriptionLink = getSubscriptionLink(type);
			window.open(subscriptionLink, '_blank');
		}
	};

	const isFullWidth = zone === 'BottomFullWidth';

	const Wrapper = isFullWidth ? ContentWrapper : 'div';

	return (
		<NewsletterWrapper isFullWidth={isFullWidth}>
			<Wrapper>
				<Header>
					<Icon iconName={icon} />
					<TitleWrapper>
						{widgetTitle && <Title>{widgetTitle}</Title>}
						{widgetDescription && <LeadText>{widgetDescription}</LeadText>}
					</TitleWrapper>
				</Header>
				<CardContainer>
					{newsletters.map(type => (
						<NewsletterCard
							key={`newsletter-card-${type}`}
							widgetId={widgetId}
							type={type}
							numberOfCards={newsletters.length}
							chosenNewsletters={chosenNewsletters}
							onSelect={onSelect}
						/>
					))}
				</CardContainer>

				{newsletters.length > 1 && (
					<OrderContainer visible={chosenNewsletters.length > 0}>
						<ChosenIndicator>
							{chosenNewsletters.length > 0 && (
								<FormattedPlural
									value={chosenNewsletters.length}
									one={intl.formatMessage({ id: 'widget_newsletter_selected_one' })}
									other={intl.formatMessage(
										{ id: 'widget_newsletter_selected_other' },
										{ count: chosenNewsletters.length }
									)}
								/>
							)}
						</ChosenIndicator>
					</OrderContainer>
				)}

				<DisclaimerContainer>
					<p>
						<FormattedMessage
							id="widget_newsletter_data_protection"
							values={{
								a: (msg: string) => (
									<a
										href="https://www.valio.fi/tietosuoja/Valion-kuluttajamarkkinoinnin-ja-kuluttajatutkimuksen-tietosuojaseloste/"
										rel="noreferrer noopener"
										target="_blank">
										{msg}
									</a>
								),
							}}
						/>
					</p>
					<p>
						<FormattedMessage
							id="widget_newsletter_data_protection_pro"
							values={{
								a: (msg: string) => (
									<a
										href="https://www.valio.fi/tietosuoja/yritysasiakas--ja-sidosryhmarekisterin-tietosuojaseloste/"
										rel="noreferrer noopener"
										target="_blank">
										{msg}
									</a>
								),
							}}
						/>
					</p>
				</DisclaimerContainer>
			</Wrapper>
			{modalState !== 'MODAL_STATE_CLOSED' && (
				<NewsletterModal
					chosenNewsletters={chosenNewsletters}
					onClose={onClose}
					modalState={modalState}
					onSubscribe={onSubscribe}
				/>
			)}
		</NewsletterWrapper>
	);
};

export default NewsletterOrder;
