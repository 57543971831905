import * as React from 'react';
import { CountryInterface } from './interfaces';
import { styleguideImport } from 'utils/import';
import { media } from 'styleguide/helpers';
import styled from 'styled-components';
import { ArrowRightIcon } from 'styleguide/components/Icons';

const CountryItemWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;

	margin: 0 0 40px 0;
`;

const CountryWrapper = styled.li`
	display: block;
	flex: 0 1 50%;

	${media.phone560`
    flex: 0 1 33%;
  `};

	${media.desktop`
    flex: 0 1 25%;
  `};
`;

const IconWrapper = styled.span`
	overflow: hidden;
	position: relative;
	background: transparent;
	margin-bottom: ${({ theme }) => theme.grid.gutterInPx(1.5)};
	border: 1px solid ${({ theme }) => theme.colors.border};

	height: 48px;
	width: 48px;
	border-radius: 50%;

	img {
		overflow: hidden;
		display: block;
		width: auto;
		height: 48px;
		position: absolute;
		left: 68%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
`;

const LinkWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: start;

	margin: 0 0 0 21px;
`;

const CountryContainerLink = styled.span`
	${({ theme }) => theme.typography.heading};
	color: ${({ theme }) => theme.colors.buttonTertiary};
	text-transform: uppercase;
	font-size: 16px;

	text-decoration: none;
	margin: ${({ theme }) => theme.grid.gutterInPx(0.25)} 0;

	&:hover {
		color: ${({ theme }) => theme.colors.buttonTertiaryHover};
	}
`;

const CountryItemLink = styled.a`
	text-align: left;
	text-decoration: none;

	margin: ${({ theme }) => theme.grid.gutterInPx(0.25)} 0;

	font-family: ${({ theme }) => theme.fonts.secondary};
	font-size: 16px;
	color: ${({ theme }) => theme.colors.linkText};

	&:hover {
		color: ${({ theme }) => theme.colors.linkTextHover};
	}

	& > svg {
		width: 8px;
		height: 8px;
		margin: 0 0 0 2px;

		path {
			fill: ${({ theme }) => theme.colors.linkText};
		}
	}
`;

interface CountryState {
	flagIcon: React.FC | React.ComponentClass | null;
}

class Country extends React.Component<CountryInterface, CountryState> {
	public state: CountryState = {
		flagIcon: null,
	};

	public componentDidMount() {
		const { code } = this.props;
		this.loadIcon(code);
	}

	public render() {
		return <CountryWrapper>{this.renderContainer()}</CountryWrapper>;
	}

	private renderContainer = () => {
		const { name, links } = this.props;
		return (
			<CountryItemWrapper>
				{this.renderFlag()}
				<LinkWrapper>
					<CountryContainerLink>{name}</CountryContainerLink>
					{links.map(l => (
						<CountryItemLink key={`country-link-${l.url}`} rel="noopener" href={l.url}>
							{l.title}
							<ArrowRightIcon />
						</CountryItemLink>
					))}
				</LinkWrapper>
			</CountryItemWrapper>
		);
	};

	private renderFlag = () => {
		const { flagIcon } = this.state;

		let FlagIcon: React.FC | React.ComponentClass | string = 'span';
		if (flagIcon) {
			FlagIcon = flagIcon;
		}

		return (
			<>
				{FlagIcon && (
					<IconWrapper>
						<FlagIcon />
					</IconWrapper>
				)}
			</>
		);
	};

	private async loadIcon(icon: string | null) {
		if (icon) {
			const styleGuideIcon = await styleguideImport(icon);
			if (styleGuideIcon) {
				this.setState({ flagIcon: styleGuideIcon });
			}
		}
	}
}

export default Country;
