import * as React from 'react';
import { WidgetCommonProps } from '../interfaces/interfaces';
import { TextOnImageColumnsConfiguration } from './interfaces';
import styled, { ThemeContext } from 'styled-components';
import { WIDGET_SPACING_LARGE } from '../constants';
import { generate } from 'shortid';
import { Button } from 'styleguide/components/Buttons/Button';
import { media } from 'styleguide/helpers';
import { ContentThemeContext } from 'common/contexts/ContentThemeContext';

const IMAGE_ASPECT_RATIO = 3 / 4;

const TextOnImageColumnsWrapper = styled.article`
	margin: ${WIDGET_SPACING_LARGE}px auto;
	width: 100%;
	display: flex;
	flex-direction: column;

	${media.tablet`
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
	`};
`;

interface ItemWrapperProps {
	itemCount: number;
	buttonColor?: string;
}

const Item = styled.a<ItemWrapperProps>`
	width: 100%;
	padding-bottom: ${({ theme }) => theme.grid.gutterInPx(2)};

	${media.tablet<ItemWrapperProps>`
		flex: 1;
		flex-basis: calc(100% / ${({ itemCount }) => (itemCount <= 4 ? itemCount : 4)});
		max-width: calc(100% / ${({ itemCount }) => (itemCount <= 4 ? itemCount : 4)});
		padding: ${({ theme }) => theme.grid.gutterInPx(1)};
	`};
`;

const Image = styled.div`
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: ${IMAGE_ASPECT_RATIO * 100}%;
`;

const Content = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 ${({ theme }) => theme.grid.gutterInPx(3)};
	cursor: pointer;

	&:hover {
		.content-link {
			filter: brightness(1.1);
		}
	}

	.content-link {
		max-width: 100%;
		box-shadow: 0 2px 20px ${({ theme }) => theme.colors.shadow};
	}
`;

const Title = styled.p`
	color: ${({ theme }) => theme.colors.white};
	font-size: 16px;
	font-weight: 600;
	text-shadow: 0 1px 6px rgba(0, 0, 0, 0.5);

	${media.tablet`
    font-size: 11px;
  `}

	${media.desktop`
    font-size: 16px;
  `}
`;

const TextOnImageColumns: React.FC<WidgetCommonProps & TextOnImageColumnsConfiguration> = ({ widgetId, items }) => {
	const theme = React.useContext(ThemeContext);

	const itemCount = items.length;

	if (itemCount === 0) {
		return null;
	}

	return (
		<ContentThemeContext.Consumer>
			{contentTheme => (
				<TextOnImageColumnsWrapper id={widgetId} className="text-on-image-columns-wrapper">
					{items.map(({ title, imageUrl, linkText, linkUrl, linkColor }) => {
						const buttonColor = linkColor || theme.colors.buttonHighlightBackground;
						const buttonColorHover = linkColor || theme.colors.buttonHighlightBackgroundHover;
						const foregroundStyle = linkColor ? 'light' : 'dark';

						return (
							<Item key={generate()} itemCount={itemCount} href={linkUrl}>
								<Image style={{ backgroundImage: `url(${imageUrl}/570x380-text-on-image-columns)` }}>
									<Content>
										{title && <Title>{title}</Title>}
										{linkText && (
											<Button
												className="content-link"
												backgroundColor={buttonColor}
												backgroundHoverColor={buttonColorHover}
												foregroundColorStyle={foregroundStyle}
												size="medium">
												{linkText}
											</Button>
										)}
									</Content>
								</Image>
							</Item>
						);
					})}
				</TextOnImageColumnsWrapper>
			)}
		</ContentThemeContext.Consumer>
	);
};

export default TextOnImageColumns;
