import { postJson } from 'utils/service';
import { NewsletterApiPayload } from './interfaces';

async function orderNewsletters(payload: NewsletterApiPayload): Promise<{}> {
	return await postJson(`/api/ammattilaiset/newsletter/subscribe`, payload, false);
}

export const newsletterApi = {
	orderNewsletters,
};

export type NewsletterApi = typeof newsletterApi;
