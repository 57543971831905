import * as React from 'react';
import styled from 'styled-components';
import AdultIcon from 'styleguide/components/Icons/AspectGroupIcons/AdultIcon/AdultIcon';
import { media } from 'styleguide/helpers/media';
import ArrowRightIcon from 'styleguide/components/Icons/NormalIcons/ArrowRightIcon/ArrowRightIcon';
import ButtonLink from 'styleguide/components/Buttons/Button/ButtonLink';
import OutlinedButtonLink from 'styleguide/components/Buttons/OutlinedButton/OutlinedButtonLink';
import { WIDGET_SPACING_SMALL } from '../constants';
import { WidgetCommonProps } from '../interfaces/interfaces';
import { OpenPositionsConfiguration, OpenPosition } from './interfaces';
import { FormattedMessage } from 'react-intl';

const OpenPositionsWrapper = styled.article`
	margin: ${WIDGET_SPACING_SMALL}px auto;
	padding: ${({ theme }) => theme.grid.gutterInPx(2)};
	background: ${({ theme }) => theme.colors.contentSectionHiglighted};
	border-radius: 3px;

	${media.tablet`
		padding: ${({ theme }) => theme.grid.gutterInPx(2)} ${({ theme }) => theme.grid.gutterInPx(5)} ${({ theme }) =>
		theme.grid.gutterInPx(4)};
	`};
`;

const Header = styled.header`
	text-align: center;

	img {
		display: block;
		margin: 0 auto;
	}

	${media.tablet`
		display: flex;
		align-items: center;

		img {
			margin: -6px 0 0 -18px;
		}
	`};
`;

const Heading = styled.h3`
	${({ theme }) => theme.typography.heading};
	font-size: 32px;
	line-height: 36px;
	color: ${({ theme }) => theme.colors.brandPrimary};
	text-transform: uppercase;
	margin: 0;
`;

const PositionsList = styled.ul`
	list-style: none;
	padding: 0;
	margin: ${({ theme }) => theme.grid.gutterInPx(2)} 0 0;
`;

const Position = styled.li`
	display: block;

	&:nth-child(odd) {
		background: ${({ theme }) => theme.colors.white};
		border-radius: 3px;
	}
`;

const Link = styled.a`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: ${({ theme }) => theme.grid.gutterInPx(2)};
	color: ${({ theme }) => theme.colors.linkText};
	text-decoration: none;

	&:hover {
		color: ${({ theme }) => theme.colors.linkTextHover};
	}

	svg {
		width: 14px;
		height: 14px;
		margin-left: ${({ theme }) => theme.grid.gutterInPx(2)};
		flex: 0 0 auto;
		margin-top: 5px;

		path {
			fill: ${({ theme }) => theme.colors.linkText};
		}
	}
`;

const PositionTitle = styled.span`
	${media.desktop`
		flex: 1 1 30%;
	`};
`;

const Description = styled.span`
	display: none;

	${media.desktop`
		display: block;
		color: ${({ theme }) => theme.colors.bodyTextLight};
		margin-left: ${({ theme }) => theme.grid.gutterInPx(2)};
		flex: 1 1 60%;
	`};
`;

const NoPositions = styled.p`
	text-align: center;

	${media.tablet`
		text-align: left;
	`};
`;

const Footer = styled.footer`
	margin-top: ${({ theme }) => theme.grid.gutterInPx(4)};
	padding: 0 ${({ theme }) => theme.grid.gutterInPx(3)};

	${media.tablet`
		margin-top: ${({ theme }) => theme.grid.gutterInPx(3)};
		padding: 0;
	`};

	a {
		${media.tablet`
			display: inline-flex;
		`};
	}

	a + a {
		margin-top: ${({ theme }) => theme.grid.gutterInPx()};

		${media.tablet`
				margin-top: 0;
				margin-left: ${({ theme }) => theme.grid.gutterInPx()};
			`};
	}
`;

const OpenPositions: React.FC<WidgetCommonProps & OpenPositionsConfiguration> = ({
	jobPosts,
	linkUrl,
	linkTitle,
	postCount,
	heading,
}) => {
	const renderPosition = ({ link, title, description }: OpenPosition) => (
		<Position key={link}>
			<Link href={link}>
				<PositionTitle>{title}</PositionTitle>
				<Description>{description}</Description>
				<ArrowRightIcon />
			</Link>
		</Position>
	);

	return (
		<OpenPositionsWrapper className="open-positions">
			<Header>
				<AdultIcon />
				<Heading>{heading}</Heading>
			</Header>
			{postCount === 0 && (
				<NoPositions>
					<FormattedMessage id="widget_open_positions_no_available_positions" />
				</NoPositions>
			)}
			{jobPosts.length > 0 && <PositionsList>{jobPosts.map(position => renderPosition(position))}</PositionsList>}
			<Footer>
				{postCount > 0 && (
					<ButtonLink href="https://valio.rekrytointi.com/paikat/index.php?o=A_LOJ&list=1&key=" size="medium">
						<FormattedMessage id="widget_open_positions_all_open_positions" values={{ postCount }} />
					</ButtonLink>
				)}
				<OutlinedButtonLink href={linkUrl}>{linkTitle}</OutlinedButtonLink>
			</Footer>
		</OpenPositionsWrapper>
	);
};

export default OpenPositions;
